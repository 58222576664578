/* eslint-disable no-console */
import type { TokenSetIdp } from '@heimdall/client';
import {
  createCheckIdLink$,
  createRequestForeignIdpToken$,
  requestIdpToken,
} from '@heimdall/client';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Subscription } from 'rxjs';

@customElement('heimdall-rig-request-idp-token')
export class HeimdallRigRequestIdpToken extends LitElement {
  @state()
  _tokenSetIdp: TokenSetIdp | undefined;

  @state()
  _progress = '×';

  @state()
  _linkUrl: string | undefined;

  @state()
  _requestForeignIdpTokenSubscription: Subscription | null = null;

  @state()
  _checkIdLinkSubscription: Subscription | null = null;

  onLinkUrlInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this._linkUrl = target.value;
  }

  onCheckIdLinkChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const { checked } = target;
    console.log(`%cCHECK ID LINK`, 'background: pink; padding: 5px;', {
      checked,
    });

    if (!checked) {
      console.log('Turn off check id link');
      this._checkIdLinkSubscription?.unsubscribe();
      this._checkIdLinkSubscription = null;
      this._progress = '×';
      return;
    }

    this._checkIdLinkSubscription = createCheckIdLink$().subscribe({
      complete: () => {
        console.log(`%cID LINK COMPLETE`, 'background: pink; padding: 5px;');
        this._checkIdLinkSubscription = null;
        this._progress = 'ID link checked successfully';
      },
      error: (e) => {
        console.warn(`%cID LINK ERROR`, 'background: pink; padding: 5px;', e);
        this._checkIdLinkSubscription = null;
        this._progress = `Unable to check id link ${e}`;
      },
    });
  }

  onRequestForeignIdpTokenChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const { checked } = target;

    if (!checked) {
      console.log('turn off request foreign idp token');
      this._requestForeignIdpTokenSubscription?.unsubscribe();
      this._requestForeignIdpTokenSubscription = null;
      this._progress = '×';
      return;
    }

    this._progress = 'Requesting IDP token...';

    this._requestForeignIdpTokenSubscription =
      createRequestForeignIdpToken$().subscribe({
        next: (set) => {
          console.log(`%cSET`, 'background: pink; padding: 5px;', set);
          this._tokenSetIdp = set;
        },
        error: (e) => {
          console.warn(`%cERROR`, 'background: pink; padding: 5px;', e);
          this._requestForeignIdpTokenSubscription = null;
          this._progress = `Unable to read IDP token ${e}`;
        },
        complete: () => {
          console.log(`%cCOMPLETE`, 'background: pink; padding: 5px;');
          this._requestForeignIdpTokenSubscription = null;
          this._progress = 'IDP token received';
        },
      });
  }

  render() {
    return html`<div class="app">
      <h1>Passive Mode</h1>
      <ul class="operation-rig">
        <li>
          <input
            type="text"
            .value=${this._linkUrl || ''}
            placeholder="enter link url ... or empty for local default"
            @input=${this.onLinkUrlInput}
          />
        </li>
        <h2>${this._progress}</h2>
        <li>
          <label for="check-id-link"
            >Request foreign IDP token
            <input
              type="checkbox"
              .checked=${!!this._requestForeignIdpTokenSubscription}
              @click=${this.onRequestForeignIdpTokenChange}
            />
          </label>
        </li>
        <li>
          <button @click=${() => this.requestIdpToken()}>
            Request IDP token ${this._linkUrl ? `from ${this._linkUrl}` : ''}
          </button>
        </li>
        <li>
          <textarea cols="80" rows="10">
${JSON.stringify(this._tokenSetIdp, null, 2)}</textarea
          >
        </li>
        <li>
          <label>
            Check ID link
            <input
              type="checkbox"
              .checked=${!!this._checkIdLinkSubscription}
              @click=${this.onCheckIdLinkChange}
            />
          </label>
        </li>
      </ul>
    </div>`;
  }

  async requestIdpToken() {
    console.log(`%cREQUEST IDP TOKEN`, 'background: powderblue; padding: 5px;');
    this._progress = 'Linking...';

    try {
      const t = await (this._linkUrl
        ? requestIdpToken(this._linkUrl)
        : requestIdpToken());
      this._tokenSetIdp = t;
    } catch (e: unknown) {
      console.warn(`Unable to read IDP token: ${e}`);
      this._progress = `Unable to read IDP token: ${e}`;
    }
  }

  static get styles() {
    return css`
      .app {
        display: flex;
        flex-direction: column;
        margin: 30px;
        padding: 30px;
        background-image: none;
        background-color: white;
      }

      .operation-rig {
        display: flex;
        flex-direction: column;
      }

      input[type='text'] {
        min-width: 300px;
      }

      .operation-rig li {
        background: lightgray;
        margin: 5px;
        padding: 5px;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-rig-request-idp-token': HeimdallRigRequestIdpToken;
  }
}
