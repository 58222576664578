import { catchError, Observable, startWith, switchMap } from 'rxjs';
import { authState$ } from './auth-state.js';
import { AuthState } from './internal.js';
import { logoutOnError } from './logout.js';
import { createRequestForeignIdpToken$ } from './request-idp-token.js';
import { flog } from './helpers.js';

export function createAuthStateRemote$(
  linkUrl = 'https://workspace.refinitiv.com/public/heimdall/link.html',
  clientUid = 'workspace'
): Observable<AuthState> {
  const remoteLogin = true;

  return createRequestForeignIdpToken$(linkUrl, {
    silentLogIn: true,
    remoteLogin,
  }).pipe(
    flog(`Remote IDP token ${clientUid}`),
    catchError((e) => {
      if (e.message === 'login_required') {
        return createRequestForeignIdpToken$(linkUrl, {
          silentLogIn: false,
          remoteLogin,
        });
      }
      throw e;
    }),
    switchMap(() => authState$),
    startWith('LOGGING_IN' as const),
    logoutOnError('LOGGED_OUT' as AuthState, true)
  );
}
