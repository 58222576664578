/* eslint-disable no-console */
import type { AuthState } from '@heimdall/client';
import * as heimdall from '@heimdall/client';
import {
  authState$,
  clearVersionContext,
  flog,
  getVersionContext,
  logOut,
  setVersionContext,
} from '@heimdall/client';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';

addHelperSnippets();

@customElement('heimdall-rig-auth-state')
export class HeimdallRigAuthState extends LitElement {
  @state()
  _loggedInState?: AuthState;

  @state()
  _versionContext = getVersionContext();

  firstUpdated() {
    authState$
      .pipe(flog('AUTH STATE', 'background: lavenderblush; padding: 10px;'))
      .subscribe((s) => (this._loggedInState = s));
  }

  fakeExpireIdpToke() {
    const serializedIpd = localStorage.getItem('heimdall:token:idp');
    if (!serializedIpd) {
      console.log('%cNo IDP token found', 'background: gold; padding: 5px;');
      return;
    }
    const ts = JSON.parse(serializedIpd);
    const { refreshInfo } = ts;
    if (!refreshInfo) return;

    refreshInfo.expiresBy = Date.now() - 1000;
    localStorage.setItem(
      'heimdall:token:idp',
      JSON.stringify({ ...ts, refreshInfo })
    );
  }

  nukeIdpToken() {
    console.log(`%cNUKE ⚛`, 'background: palegreen; padding: 10px;');
    localStorage.removeItem('heimdall:token:idp');
  }

  onVersionContextClear() {
    clearVersionContext();
    this.onVersionContextReload();
  }

  onVersionContextInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const newContext = target.value;
    setVersionContext(newContext);
    this.onVersionContextReload();
  }

  onVersionContextReload() {
    const newContext = getVersionContext();
    this._versionContext = newContext;
  }

  render() {
    return html`
      <div class="app">
        <h1>${this._loggedInState || '×'}</h1>
        <ul>
          <li>
            <button @click=${this.fakeExpireIdpToke}>
              Fake expire IDP token (set expiration to -1 second)
            </button>
          </li>
          <li>
            <button @click=${this.nukeIdpToken}>Nuke IDP token</button>
          </li>
          <li>
            <button @click=${logOut}>Log out</button>
          </li>
          <li>
            <label for="test-url"
              >Version context:
              <input
                type="text"
                .value=${this._versionContext}
                @input=${this.onVersionContextInput}
              />
              <button @click=${this.onVersionContextClear}>Clear</button>
              <button @click=${this.onVersionContextReload}>Reload</button>
            </label>
          </li>
        </ul>
      </div>
    `;
  }
  static get styles() {
    return css`
      .app {
        display: flex;
        flex-direction: column;
        margin: 30px;
        padding: 30px;
        background-image: none;
        background-color: white;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-rig-auth-state': HeimdallRigAuthState;
  }
}

export function addHelperSnippets() {
  // eslint-disable-next-line
  const w: any = window;
  w.heimdall = heimdall;
}
