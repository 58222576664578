import { estimatedNowAtServerMs } from './estimated-now-at-server';
import {
  IdpConfig,
  RefreshInfo,
  TokenBundleIdp,
  TokenSetIdp,
  assertIsRefreshableTokenSet,
} from './internal.js';
import { decodeJwtPayload } from './jwt-helper.js';

export function isFreshTokenBundle<T extends { refreshInfo?: RefreshInfo }>(
  bundle: T
) {
  if (!bundle.refreshInfo) return true;
  return bundle.refreshInfo.expiresBy > estimatedNowAtServerMs();
}

export function packWebTokenBundleIdp(
  tokenEndpoint: string,
  tokenSet: TokenSetIdp,
  idpConfig: IdpConfig
): TokenBundleIdp {
  assertIsRefreshableTokenSet(tokenSet);
  const base = { tokenSet, idpConfig };
  return {
    ...base,
    refreshInfo: {
      tokenEndpoint,
      refreshToken: tokenSet.refresh_token,
      expiresBy: estimatedNowAtServerMs() + tokenSet.expires_in * 1000,
    },
  };
}

export function packNativeTokenBundleIdp(
  idpConfig: IdpConfig,
  nativeToken: string
): TokenBundleIdp {
  const {
    idpKey = 'entra',
    dpopMode,
    clientUid,
    defaultAudiences,
    heimdallTokenEndpoint,
    swapTokenType,
  } = idpConfig;

  const tokenPayload = decodeJwtPayload(nativeToken);

  const idpTokenBundle: TokenBundleIdp = {
    tokenSet: { [swapTokenType]: nativeToken },
    idpConfig: {
      idpKey,
      clientUid,
      heimdallTokenEndpoint,
      swapTokenType,
      defaultAudiences,
      dpopMode,
    },
    refreshInfo: {
      expiresBy: tokenPayload.exp
        ? tokenPayload.exp * 1000
        : estimatedNowAtServerMs() + 30 * 1000,
    },
  };

  return idpTokenBundle;
}
