/* eslint-disable no-console */
import type { AuthState } from '@heimdall/client';
import {
  authState$,
  getApiToken,
  flog,
  optOut,
  apiToken$,
  idpToken$,
} from '@heimdall/client';
import '@refinitiv-ui/elements/accordion';
import '@refinitiv-ui/elements/accordion/themes/halo/light';
import '@refinitiv-ui/elements/button';
import '@refinitiv-ui/elements/loader';
import { CSSResult, LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { appConfig$ } from '../config.js';
import '../theme.js';
import logoUrl from './../assets/lseg_logo_rgb_pos.png';
import { addHelperSnippets } from './heimdall-rig-auth-state.js';

addHelperSnippets();

@customElement('heimdall-success')
export class HeimdallSuccess extends LitElement {
  @state()
  _isAuthenticated = false;

  @state()
  _callResult = '';

  @state()
  _env = '';

  @state()
  _loginUrl = '';

  @state()
  _postLogoutUrl = '';

  @state()
  _endpointUrl = '';

  @state()
  _nextPath = '/web/Apps/news/';

  @state()
  _authState: AuthState = 'LOGGED_OUT';

  constructor() {
    super();
  }

  firstUpdated() {
    appConfig$.subscribe(({ webLoginUrl, env, postLogoutUrl }) => {
      const nextPath = encodeURIComponent(
        '/public/heimdall/success/index.html'
      );
      this._loginUrl = `${webLoginUrl}?nextPath=${nextPath}&clientUid=workspace`;
      this._postLogoutUrl = `${postLogoutUrl}?nextPath=${nextPath}&clientUid=workspace`;
      this._env = env;
      this._endpointUrl = `https://amers1-apps.${
        env === 'ppe' ? 'ppe' : 'qa'
      }.platform.refinitiv.com/Apps/NewsServices/topNews/trendingTopics?apiKey=ecf03882-a2c2-430d-b911-728f69e9e7a3&productName=TopNewsApp&id=urn:newsml:reuters.com:20020923:SPDOC_119817232002`;
    });
    authState$
      .pipe(flog(`AUTH STATE -- RIG`, 'background: pink; padding: 15px;'))
      .subscribe({
        next: (state) => {
          const isAuthenticated = state === 'LOGGED_IN';
          this._isAuthenticated = isAuthenticated;
          this._authState = state;
        },
      });

    apiToken$.subscribe({
      next: ({ token, expiresBy }) => {
        console.log('TOKEN UPDATE', `exp: ${new Date(expiresBy)}`, token);
      },
    });
    idpToken$.subscribe({
      next: (idpToken) => {
        console.log('IDP TOKEN UPDATE', idpToken && idpToken.id_token);
      },
    });
  }

  onEndpointInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this._endpointUrl = target.value;
  }

  onNextPathInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this._nextPath = target.value;
  }

  render() {
    return html`<div class="app">
      <div class="header">
        <img src=${logoUrl} class="logo" alt="Refinitiv logo" />
        <h1>Token Testing Application</h1>
      </div>
      <div class="content">${this.getAppContent()}</div>
      <div class="footer"></div>
    </div>`;
  }

  getAppContent() {
    if (this._authState === 'LOGGED_IN') {
      return html` <div>
        <h2>
          State: ${this._authState}
          <ef-button
            style="float: right;"
            @click=${async () => {
              await optOut();
              window.location.assign(this._postLogoutUrl);
            }}
          >
            Sign Out</ef-button
          >
        </h2>
        <br /><br />
        <ef-accordion auto-collapse-disabled>
          <ef-collapse header="Redirect to Workspace apps">
            <div class="accordion-content">
              <ef-button
                class="button"
                ?disabled=${!this._isAuthenticated}
                @click=${() => (window.location.href = '/web/')}
              >
                Redirect to: /web/
              </ef-button>
              <ef-button
                class="button"
                ?disabled=${!this._isAuthenticated}
                @click=${() => (window.location.href = '/web/Apps/news/')}
              >
                Redirect to: /web/Apps/news/
              </ef-button>
              <br />
              <label for="test-redirect">Target application url:</label>
              <input
                id="test-redirect"
                class="next-path"
                .value=${this._nextPath}
                @input=${this.onNextPathInput}
                placeholder="next path"
                type="text"
              />
              <ef-button
                @click=${() => (window.location.href = this._nextPath)}
              >
                Redirect to target application
              </ef-button>
            </div>
          </ef-collapse>
          <ef-collapse expanded header="Retrieve backend data">
            <div class="accordion-content">
              <label for="test-url">Endpoint:</label>
              <textarea
                id="test-url"
                class="endpoint"
                type="text"
                .value=${this._endpointUrl}
                @input=${this.onEndpointInput}
                placeholder="endpoint url"
              ></textarea>
              <ef-button @click=${() => this.callEndpoint(this._endpointUrl)}>
                Call Endpoint
              </ef-button>
              <pre class="result">${this._callResult}</pre>
            </div>
          </ef-collapse>
        </ef-accordion>
      </div>`;
    }

    if (
      this._authState === 'LOGGED_OUT' ||
      this._authState === 'LOGIN_EXPIRED'
    ) {
      return html`<h2>State: ${this._authState}</h2>
        <ef-button @click=${() => window.location.assign(this._loginUrl)}
          >Log in</ef-button
        >`;
    }

    return html`<h2>State: ${this._authState}</h2>
      <ef-loader></ef-loader>`;
  }

  async callEndpoint(url: string) {
    this._callResult = 'loading...';
    const { token } = await getApiToken();
    fetch(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} — ${response.statusText}`);
        }
        return response.json();
      })
      .then((json) => {
        console.log('RESULT', json);
        this._callResult = JSON.stringify(json, null, 2);
      })
      .catch((e: Error) => {
        this._callResult = `Couldn't load resource: ${e.message.toString()}`;
      });
  }

  static get styles(): CSSResult {
    return css`
      .button {
        margin: 0 10px 10px 0;
      }
      .app {
        display: flex;
        flex-direction: column;
        margin: 30px;
        min-height: 70vh;
        background-image: none;
        background-color: #fff;
      }
      .header {
        display: flex;
        padding: 40px 30px 0;
        align-items: center;
        gap: 40px;
        flex-wrap: wrap;
      }
      h1 {
        margin: 0;
      }
      .logo {
        display: block;
        width: 212px;
      }

      .content {
        flex-grow: 1;
        display: block;
        padding: 50px 30px 20px;
      }

      .accordion-content {
        padding: 20px 10px 30px;
      }

      .footer {
        width: 100%;
        height: 40px;
        background-color: #001aff;
      }
      .endpoint {
        width: 100%;
        margin-bottom: 10px;
        min-height: 60px;
      }
      .next-path {
        width: 100%;
        margin-bottom: 10px;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-success': HeimdallSuccess;
  }
}
