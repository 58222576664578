import { NotificationWSClient } from '@refinitiv/pns2-message-client';
import {
  MonoTypeOperatorFunction,
  Observable,
  Subscription,
  combineLatest,
} from 'rxjs';
import { env$ } from './env.js';
import { TokenBundleHeimdall, TokenByAudience } from './internal.js';
import { notifyRefresh$ } from './token-refresh.js';

const ESO_TOPIC_NAME = {
  dev: 'heimdall_token_dev',
  qa: 'heimdall_token_qa',
  ppe: 'heimdall_token',
  prod: 'heimdall_token',
};

export const perSessionEsoListenerOperator = createEsoListener(
  (notificationReference) => notifyRefresh$.next(`PN.${notificationReference}`)
);

function createEsoListener(
  callback: (notificationReference: string) => void
): MonoTypeOperatorFunction<TokenBundleHeimdall> {
  let notificationClient: NotificationWSClient | null = null;
  let esoSubscription: Subscription | null = null;
  return (source$) => {
    return new Observable<TokenBundleHeimdall>((o) => {
      combineLatest([source$, env$]).subscribe({
        next: ([bundle, env]) => {
          const tokenByAudience: TokenByAudience = bundle.tokenSet.access_token;
          const subscriptionToken = tokenByAudience['api.ws'];
          const elevatedToken =
            tokenByAudience['data.ws'] || tokenByAudience['restricted.ws'];
          if (
            env &&
            !notificationClient &&
            subscriptionToken &&
            elevatedToken
          ) {
            notificationClient = new NotificationWSClient(
              subscriptionToken,
              env
            );

            notificationClient
              .subscribe(
                ESO_TOPIC_NAME[env],
                ({ Data: notificationReference }: { Data: string }) => {
                  const serverTimestamp = parseInt(notificationReference);
                  if (!isNaN(serverTimestamp)) {
                    // eslint-disable-next-line no-console
                    console.info(
                      'PN: diff between server time and UI (in ms)',
                      Date.now() - serverTimestamp
                    );
                  }
                  if (elevatedToken) {
                    callback(notificationReference);
                  }
                }
              )
              .then((subscription) => {
                esoSubscription = subscription;
              })
              .catch((e) => o.error(e));
          }
          o.next(bundle);
        },
        error: (e) => o.error(e),
        complete: () => o.complete(),
      });

      return () => {
        esoSubscription?.unsubscribe();
      };
    });
  };
}
