import {
  MonoTypeOperatorFunction,
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  startWith,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import {
  createDistributedObservable$,
  createDistributedSubject$,
} from './distributed-observable.js';
import { idpTokenBundle$ } from './idp.js';
import { AuthState, TokenBundleIdp } from './internal.js';
import { isFreshTokenBundle } from './token-time.js';
import { flog } from './helpers.js';
import { createInterest$ } from './distributed-interest.js';

const loggingInSignal$ = createDistributedSubject$<boolean>(
  'heimdall:logging-in-signal'
);

export const authState$ = createDistributedObservable$<AuthState>(
  'heimdall:auth-state',
  (o, interest$) => {
    const internal = new Subscription();
    let loggingIn = false;
    let maybeTokenBundleIdp: TokenBundleIdp | null;

    internal.add(
      createInterest$('heimdall:idp-login-change').subscribe(updateOnState)
    );

    internal.add(
      idpTokenBundle$
        .pipe(tap((n) => (maybeTokenBundleIdp = n)))
        .subscribe(updateOnState)
    );

    internal.add(
      loggingInSignal$
        .pipe(
          flipBackOnTimeout(),
          distinctUntilChanged(),
          debounceTime(500),
          flog('Logging in signal')
        )
        .subscribe((s) => {
          loggingIn = s;
          updateOnState();
        })
    );

    internal.add(interest$?.subscribe(updateOnState));

    return internal;

    function updateOnState() {
      if (loggingIn) {
        o.next('LOGGING_IN');
        return;
      }

      o.next(
        !maybeTokenBundleIdp
          ? 'LOGGED_OUT'
          : isFreshTokenBundle(maybeTokenBundleIdp)
          ? 'LOGGED_IN'
          : 'LOGIN_EXPIRED'
      );
    }
  }
).pipe(distinctUntilChanged(), flog('Auth state'));

export function signalLogIn<T>(): MonoTypeOperatorFunction<T> {
  return function (source$) {
    return new Observable<T>((o) => {
      const subscription = source$.subscribe(o);
      loggingInSignal$.next(true);

      return () => {
        subscription.unsubscribe();
        loggingInSignal$.next(false);
      };
    });
  };
}

function flipBackOnTimeout(timeout = 30000): MonoTypeOperatorFunction<boolean> {
  return switchMap((v) => {
    if (v) {
      return timer(timeout).pipe(
        map(() => false),
        startWith(true),
        flog('Timeout')
      );
    }

    return of(false);
  });
}
