import {
  SwapTokenType,
  alignLocalStorageValue,
  assertIsIdpBundle,
  createRetryFetch$,
  estimatedNowAtServerMs,
  flog,
  getLocalStorageValue,
  loadStructure,
  setVersionContext,
  signalLogIn,
} from '@heimdall/client';
import {
  Observable,
  Subject,
  catchError,
  filter,
  map,
  merge,
  of,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';

import { appConfig$ } from './config.js';
import { guessContext } from './context-heuristic.js';
import {
  BaseConfig,
  IdpContext,
  InteractionState,
  OpenIdConfig,
  RedirectionStateFields,
  TokenSwapOpenIdConfig,
  createIssuerConfig$,
  finalizeLogin,
  validateNextPath,
} from './internal.js';
import { clearTransientLocalFields, setLocalFields } from './local-fields.js';
import { codeVerifierAndChallenge$ } from './verifier-and-challenge.js';

export const selectPreferredIdp$ = new Subject<string>();
const initialSearchParams = new URLSearchParams(document.location.search);

const FRESH_BUNDLE_MARGIN = 60 * 1000;

export function createLoginWeb$(): Observable<InteractionState> {
  return appConfig$.pipe(
    validateNextPath(),
    tap(() => setVersionContext(guessContext())),
    switchMap((appConfig) => {
      const {
        issuerUrl,
        clientUid: clientUidProvided,
        redirectUri,
        nextPath,
        env,
        loginHint,
        forceRedirect,
      } = appConfig;

      const clientUid = clientUidProvided || 'workspace';

      alignLocalStorageValue('heimdall:env', env);
      clearTransientLocalFields();

      return createOptimisticLoginJourney$(nextPath, forceRedirect).pipe(
        catchError(() =>
          createFullLoginJourney$({
            issuerUrl,
            clientUid,
            redirectUri,
            nextPath,
            loginHint,
          })
        )
      );
    }),

    catchError((error) => {
      return of<InteractionState>({
        state: 'ERROR',
        error,
      });
    }),
    startWith({
      state: 'STAND_BY' as const,
      reason: 'Loading config...',
    }),
    signalLogIn()
  );
}

function createPreferredIdp$(
  { idps }: TokenSwapOpenIdConfig,
  sideChainSignal$: Subject<InteractionState>
) {
  const { availableIdps, defaultIdp } = toAvailableIdps(idps);
  if (defaultIdp)
    return of(defaultIdp).pipe(flog('Preferred IDP from default.'));

  const preferredIdp = getPreferredIdp();
  if (preferredIdp && availableIdps.includes(preferredIdp))
    return of(preferredIdp).pipe(
      flog('Preferred IDP from query param or local cache.')
    );

  sideChainSignal$.next({ state: 'REQUIRES_IDP_SELECTION', availableIdps });

  return selectPreferredIdp$.pipe(
    flog('Manual IDP selection'),
    filter(Boolean),
    filter((idp) => availableIdps.includes(idp)),
    take(1)
  );
}

function getPreferredIdp() {
  return getPreferredIdpFromUrl() || getPreferredIdpFromLocalStorage();
}

function getPreferredIdpFromUrl(): string | null {
  return initialSearchParams.get('idp');
}

function getPreferredIdpFromLocalStorage(): string | null {
  return getLocalStorageValue('heimdall:preferred-idp');
}

export function toAvailableIdps(idpSet: Record<string, IdpContext>): {
  defaultIdp: string | undefined;
  availableIdps: string[];
} {
  const availableIdps = Object.keys(idpSet).filter(
    (idp) => !idp.startsWith('_')
  );

  const defaultIdp = availableIdps.length === 1 ? availableIdps[0] : undefined;
  return { defaultIdp, availableIdps };
}

function setPreferredIdp(idp: string) {
  alignLocalStorageValue('heimdall:preferred-idp', idp);
}

function assertIsSwapTokenType(swap: unknown): asserts swap is SwapTokenType {
  if (swap === 'access_token' || swap === 'id_token') return;
  throw new Error(`Unrecognized swap type ${swap}`);
}

function createLoadIdpConfig$(idp: IdpContext) {
  const { well_known: wellKnown, client_id: clientId } = idp;
  return createRetryFetch$<OpenIdConfig>(wellKnown).pipe(
    map((idpConfig) => {
      return { idpConfig, clientId };
    })
  );
}

export function createLoginUrl(loginUrlConfig: {
  clientId: string;
  scope: string;
  state: string;
  authorizationEndpoint: string;
  redirectUri: string;
  codeChallenge: string;
  loginHint?: string;
  silent?: boolean;
}) {
  const {
    clientId,
    scope,
    state,
    authorizationEndpoint,
    redirectUri,
    codeChallenge,
    loginHint,
    silent,
    ...rest
  } = loginUrlConfig;

  const params = new URLSearchParams({
    client_id: clientId,
    scope,
    state,
    redirect_uri: redirectUri,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    response_type: 'code',
    ...(loginHint ? { login_hint: loginHint } : {}),
    ...(silent ? { prompt: 'none' } : {}),
    ...rest,
  });

  return `${authorizationEndpoint}?${params}`;
}

export function createLoginUrlConfig(
  {
    idpKey,
    clientUid,
    clientId,
    nextPath,

    openIdConfig,
    redirectUri,
    codeChallenge,
    scopesRequired,
    loginHint,
    silent,
  }: LoginUrlConfig,

  relativeTokenEndpoint = false
) {
  const authorizationEndpoint = openIdConfig.authorization_endpoint;
  const tokenEndpoint = relativeTokenEndpoint
    ? new URL(openIdConfig.token_endpoint).pathname
    : openIdConfig.token_endpoint;

  const { scopes_supported: scopesSupported } = openIdConfig;
  const scope = scopeForIdpLogin(clientId, scopesSupported, scopesRequired);

  const state = encodeRedirectionState({
    idpKey,
    clientUid,
    clientId,
    tokenEndpoint,
    nextPath,
  });

  return {
    clientId,
    scope,
    state,
    authorizationEndpoint,
    tokenEndpoint,
    redirectUri,
    codeChallenge,
    loginHint,
    silent,
  };
}

type LoginUrlConfig = BaseConfig & {
  openIdConfig: OpenIdConfig;
  redirectUri: string;
  codeChallenge: string;
  scopesRequired?: string;
  loginHint?: string;
  silent?: boolean;
};

function encodeRedirectionState(state: RedirectionStateFields) {
  return encodeURI(JSON.stringify(state));
}

export function scopeForIdpLogin(
  clientId: string,
  scopesSupported: string[],
  scopesRequired: string | undefined
) {
  if (scopesRequired) {
    scopesSupported.push(prependClientIfMissing(clientId, scopesRequired));
  }
  return scopesSupported.join(' ');
}

function prependClientIfMissing(clientId: string, scopesRequired: string) {
  if (scopesRequired.length > clientId.length) {
    return scopesRequired;
  }
  return `${clientId}/${scopesRequired}`;
}

export function createFullLoginJourney$({
  issuerUrl,
  clientUid,
  redirectUri,
  nextPath,
  loginHint,
  linkOriginKey,
  silent,
}: {
  issuerUrl: string;
  clientUid: string;
  redirectUri: string;
  nextPath: string;
  loginHint?: string;
  linkOriginKey?: string;
  silent?: boolean;
}) {
  const sideChainSignal$ = new Subject<InteractionState>();

  return merge(
    sideChainSignal$,
    createIssuerConfig$(issuerUrl, clientUid).pipe(
      withLatestFrom(codeVerifierAndChallenge$),
      switchMap(([config, { codeChallenge, codeVerifier }]) => {
        const {
          token_endpoint: heimdallTokenEndpoint,
          frontchannel_logout_supported: frontChannelLogoutSupported,
          frontchannel_logout_uri: heimdallTokenLogoutUri,
          dpop: dpopMode,
          client_type: clientType,
        } = config;

        return createPreferredIdp$(config, sideChainSignal$).pipe(
          flog('Preferred IDP'),
          tap(setPreferredIdp),
          switchMap((idpKey) => {
            const idpContext = config.idps[idpKey];
            const { scopes_required: scopesRequired, swap: swapTokenType } =
              idpContext;

            assertIsSwapTokenType(swapTokenType);

            return createLoadIdpConfig$(idpContext).pipe(
              flog('Idp Config'),
              map(({ idpConfig, clientId }) => {
                const { end_session_endpoint: idpEndSessionEndpoint } =
                  idpConfig;

                setLocalFields({
                  codeVerifier,
                  redirectUri,
                  heimdallTokenEndpoint,
                  idpEndSessionEndpoint,
                  swapTokenType,
                  dpopMode,
                  clientType,
                  ...(linkOriginKey ? { linkOriginKey } : {}),
                  ...(frontChannelLogoutSupported && heimdallTokenLogoutUri
                    ? { heimdallTokenLogoutUri }
                    : {}),
                });

                return createLoginUrlConfig({
                  idpKey,
                  openIdConfig: idpConfig,
                  clientUid,
                  clientId,
                  redirectUri,
                  nextPath,
                  codeChallenge,
                  scopesRequired,
                  loginHint,
                  silent,
                });
              })
            );
          }),
          map(createLoginUrl),
          flog(`Login URL`),
          tap((loginUrl) => document.location.assign(loginUrl)),
          tap(() =>
            sideChainSignal$.next({
              state: 'STAND_BY',
              reason: 'Redirecting to IDP login URL',
            })
          ),
          map(
            (): InteractionState => ({
              state: 'STAND_BY',
              reason: 'Redirecting',
            })
          )
        );
      })
    )
  );
}

function createOptimisticLoginJourney$(
  nextPath: string,
  forceRedirect: boolean
) {
  if (forceRedirect) {
    return throwError(() => new Error('Force redirect'));
  }

  return of(loadStructure('heimdall:token:idp')).pipe(
    flog('Stored IDP token bundle'),
    map((bundle) => {
      if (!bundle) throw new Error('No IDP token bundle found');
      assertIsIdpBundle(bundle);
      if (
        bundle.refreshInfo.expiresBy + FRESH_BUNDLE_MARGIN <
        estimatedNowAtServerMs()
      ) {
        throw new Error('IDP token bundle expired');
      }
      return bundle;
    }),
    finalizeLogin(nextPath)
  );
}
