import type { AuthState } from '@heimdall/client';
import { authState$, flog } from '@heimdall/client';
import '@refinitiv-ui/elements/button';
import '@refinitiv-ui/elements/loader';
import { CSSResult, LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { distinctUntilChanged } from 'rxjs';
import '../components/heimdall-frame.js';
import { appConfig$, isNextPathValid } from '../config.js';
import '../theme.js';
import msLogoUrl from './../assets/ms-symbollockup_mssymbol_19.png';

@customElement('heimdall-logout')
export class HeimdallLogout extends LitElement {
  @property({ type: Boolean })
  entraButtonEnabled = false;

  @property({ type: Boolean })
  forgeRockButtonEnabled = false;

  @state()
  _loggedInState: AuthState = 'LOGGED_OUT';

  @state()
  _entraLoginUrl = '';

  @state()
  _forgeRockLoginUrl = '';

  firstUpdated() {
    appConfig$.subscribe(({ webLoginUrl, clientUid, nextPath }) => {
      if (nextPath && isNextPathValid(nextPath)) {
        if (this.entraButtonEnabled) {
          this._entraLoginUrl = `${webLoginUrl}?nextPath=${encodeURIComponent(
            nextPath
          )}&clientUid=${clientUid || 'workspace'}`;
          authState$
            .pipe(distinctUntilChanged(), flog('Logged out state'))
            .subscribe((loggedInState) => {
              this._loggedInState = loggedInState;
              if (loggedInState === 'LOGGED_IN') {
                document.location.replace(nextPath);
              }
            });
        }
        if (this.forgeRockButtonEnabled) {
          this._forgeRockLoginUrl = nextPath;
        }
      }
    });
  }

  render() {
    return html`<heimdall-frame> ${this.getFrameContent()} </heimdall-frame>`;
  }

  getFrameContent() {
    if (this._loggedInState === 'LOGGING_IN') {
      return html`<h2>Logging in...</h2>
        <ef-loader></ef-loader>`;
    }

    if (this._loggedInState === 'LOGGED_IN') {
      return html`<h2>Loading...</h2>
        <ef-loader></ef-loader>`;
    }

    return html`
      <h2>You have been successfully signed out.</h2>
      <div class="buttons">
        ${this._entraLoginUrl
          ? html`<ef-button
              @click=${() => window.location.replace(this._entraLoginUrl)}
              ><span class="label"
                ><img
                  src=${msLogoUrl}
                  class="logo"
                  alt="Microsoft Logo"
                  aria-hidden="true"
                />Sign back in with Microsoft</span
              ></ef-button
            >`
          : ''}
        ${this._forgeRockLoginUrl
          ? html`<ef-button
              @click=${() => window.location.replace(this._forgeRockLoginUrl)}
              ><span class="label"
                >Sign back in using LSEG Authentication</span
              ></ef-button
            >`
          : ''}
      </div>
    `;
  }

  static get styles(): CSSResult {
    return css`
      h2 {
        margin-bottom: 30px;
      }
      .buttons {
        display: grid;
        width: fit-content;
        margin: 0 auto;
        gap: 20px;
      }
      ef-button {
        height: 41px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        color: #5e5e5e;
        background-color: #fff;
        border: 1px solid #8c8c8c;
        font-size: 14px;
      }
      ef-button:hover,
      ef-button:focus {
        color: #000;
        border-color: #000;
      }
      ef-button .label {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-logout': HeimdallLogout;
  }
}
