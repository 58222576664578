/* eslint-disable no-console */
import type { TokenAudience, TokenUpdate } from '@heimdall/client';
import {
  apiToken$,
  claimRealTimeData,
  connectToken$,
  dataToken$,
  getApiToken,
  getConnectToken,
  getDataToken,
  getRestrictedToken,
  getThirdpartyToken,
  isHeimdallEnabled,
  logOut,
  notifyRefresh$,
  restrictedToken$,
  thirdpartyToken$,
} from '@heimdall/client';
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Observable, Subscription } from 'rxjs';
import { appConfig$ } from '../config.js';
import { setupSharedTools } from './shared-tools.js';

setupSharedTools();

const audiences: TokenAudience[] = [
  'api.ws',
  'data.ws',
  'restricted.ws',
  'thirdparty.ws',
  'connect.ws',
];

const streamByAudience: Record<TokenAudience, Observable<TokenUpdate>> = {
  ['api.ws']: apiToken$,
  ['data.ws']: dataToken$,
  ['restricted.ws']: restrictedToken$,
  ['thirdparty.ws']: thirdpartyToken$,
  ['connect.ws']: connectToken$,
};

const singleTokenByAudience: Record<TokenAudience, () => Promise<TokenUpdate>> =
  {
    ['api.ws']: getApiToken,
    ['data.ws']: getDataToken,
    ['restricted.ws']: getRestrictedToken,
    ['thirdparty.ws']: getThirdpartyToken,
    ['connect.ws']: getConnectToken,
  };

@customElement('heimdall-rig-audience')
export class HeimdallRigAudience extends LitElement {
  _debugInstanceId = Math.floor(Math.random() * 0xfff)
    .toString(32)
    .toUpperCase();

  @state()
  _subscriptionByAudience: Partial<Record<TokenAudience, Subscription>> = {};

  @state()
  _updatesByAudience: Partial<Record<TokenAudience, TokenUpdate[]>> = {};

  @state()
  _updateByAudience: Partial<Record<TokenAudience, TokenUpdate>> = {};

  @state()
  _singlePendingByAudience: Partial<Record<TokenAudience, boolean>> = {};

  @state()
  _error?: unknown;

  onIsHeimdallEnabled() {
    alert(
      isHeimdallEnabled()
        ? `Yes, Heimdall is enabled — fresh tokens currently available`
        : `No, Heimdall is not enabled — no fresh tokens currently available.`
    );
  }

  protected firstUpdated() {
    appConfig$.subscribe((config) => {
      console.log(
        `%cAPP CONFIG`,
        'background: mintcream; padding: 20px;',
        config
      );
    });
  }

  onGetSingleTokenRequest(audience: TokenAudience) {
    return async () => {
      this._singlePendingByAudience = {
        ...this._singlePendingByAudience,
        [audience]: true,
      };
      const update = await singleTokenByAudience[audience]();
      delete this._singlePendingByAudience[audience];
      this._singlePendingByAudience = {
        ...this._singlePendingByAudience,
      };
      this._updateByAudience = {
        ...this._updateByAudience,
        [audience]: update,
      };
    };
  }

  onSubscriptionRequestChanged(audience: TokenAudience) {
    return (event: Event) => {
      const target = event.target as HTMLInputElement;
      const { checked } = target;

      if (checked) {
        this._subscriptionByAudience = {
          ...this._subscriptionByAudience,
          [audience]: streamByAudience[audience].subscribe({
            next: (update) => {
              const currentUpdates = this._updatesByAudience[audience] || [];
              this._updatesByAudience[audience] = [...currentUpdates, update];
              this._updatesByAudience = { ...this._updatesByAudience };
            },
            complete: () => {
              this.removeSubscription(audience);
            },
          }),
        };
      } else {
        this.removeSubscription(audience);
      }
    };
  }

  removeSubscription(audience: TokenAudience) {
    this._subscriptionByAudience[audience]?.unsubscribe();
    delete this._subscriptionByAudience[audience];
    this._updatesByAudience[audience] = [];
    this._updatesByAudience = { ...this._updatesByAudience };
    this._subscriptionByAudience = { ...this._subscriptionByAudience };
  }

  render() {
    if (this._error)
      return html`<div class="app">
        <h1>💀</h1>
        <pre>${this._error}</pre>
      </div>`;
    return html`
      <div class="app">
        <h2>${this._debugInstanceId}</h2>
        <ul class="operation-rig">
          ${audiences.map((audience) => {
            const active =
              !!this._subscriptionByAudience[audience] &&
              !this._subscriptionByAudience[audience]?.closed; // perhaps redundant
            return html`
              <li>
                <label class="${active ? 'active' : ''}">
                  <input
                    type="checkbox"
                    .checked=${active}
                    @change=${this.onSubscriptionRequestChanged(audience)}
                  />
                  Subscribe to token updates for ${audience}
                </label>
                <p>
                  ${(this._updatesByAudience[audience] || []).map((update) => {
                    return createUpdate(audience, update);
                  })}
                </p>
                <hr />
                <label
                  class=${this._singlePendingByAudience[audience]
                    ? 'pending'
                    : ''}
                >
                  <button @click=${this.onGetSingleTokenRequest(audience)}>
                    Get single token update
                  </button>
                  for ${audience}</label
                >
                <p>
                  ${this._updateByAudience[audience] &&
                  createUpdate(audience, this._updateByAudience[audience])}
                </p>
              </li>
            `;
          })}
          <li>
            <p>
              <button
                @click=${() => {
                  notifyRefresh$.next(`PN.${Date.now()}`);
                }}
              >
                Notify refresh (REF ${this._debugInstanceId})
              </button>
            </p>
            <p>
              <button @click=${claimRealTimeData}>
                Reclaim DATA real-time
              </button>
            </p>
            <p>
              <button @click=${this.onIsHeimdallEnabled}>
                Is Heimdall enabled?
              </button>
            </p>
            <p>
              <button @click=${logOut}>Log out</button>
            </p>
          </li>
        </ul>
      </div>
    `;
  }

  static get styles() {
    return css`
      .operation-rig li {
        background: powderblue;
        margin: 5px;
        padding: 5px;
      }

      .active {
        background: pink;
        padding: 5px;
      }

      .pending {
        background: aqua;
        padding: 5px;
      }

      .app {
        display: flex;
        flex-direction: column;
        margin: 30px;
        padding: 30px;
        background-image: none;
        background-color: white;
      }

      .token {
        padding: 5px 10px;
        margin: 2px 5px;
        background: aliceblue;
        outline: 1px dashed gray;
        white-space: nowrap;
        font-family: monospace;
        font-size: small;
        margin: 3px;
        border-radius: 15px;
        display: inline-block;
      }
      .token.realtime {
        background: yellow;
      }
      .token.delayed {
        background: beige;
      }
      .token.default {
        background: lavender;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-rig-audience': HeimdallRigAudience;
  }
}

function createUpdate(audience: TokenAudience, tokenUpdate?: TokenUpdate) {
  if (!tokenUpdate) return;

  if ('isRealTime' in tokenUpdate) {
    const { isRealTime } = tokenUpdate;

    return createTokenPill(
      `T ∴ ${audience}·${tokenUpdate.token.slice(-4)} ${
        isRealTime ? `RT:📲` : `D:📞`
      }`,
      isRealTime ? 'realtime' : 'delayed'
    );
  }

  return createTokenPill(`T ∴ ${audience}·${tokenUpdate.token.slice(-4)} 🎫`);
}

function createTokenPill(token: string, extraClass = '') {
  return html`<span class="token ${extraClass}">${token}</span> `;
}
