import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { TokenBundleHeimdall, TokenByAudience } from './internal.js';
import { createRetryFetch$, flog, log } from './helpers.js';

export function createUpdateCdn$(heimdallToken = '', includeAuthMode = false) {
  return createRetryFetch$(
    `/auth/heimdall${includeAuthMode ? '?persist=true' : ''}`,
    heimdallToken
      ? {
          method: 'POST',
          headers: { Authorization: `Bearer ${heimdallToken}` },
          skipParse: true,
        }
      : {
          method: 'DELETE',
          skipParse: true,
        }
  ).pipe(flog(`Update CDN with token`));
}

export const perSessionCdnUpdateOperator = updateCdnOnDefaultTokenChange();

function updateCdnOnDefaultTokenChange(): MonoTypeOperatorFunction<TokenBundleHeimdall> {
  let firstTimeForSession = true;
  let lastBroadcastedToken = '';
  return (source$) => {
    return new Observable<TokenBundleHeimdall>((o) => {
      return source$.subscribe({
        next: (bundle) => {
          const tokenByAudience: TokenByAudience = bundle.tokenSet.access_token;
          const defaultToken: string | undefined = tokenByAudience['api.ws'];

          if (!defaultToken || defaultToken === lastBroadcastedToken) {
            o.next(bundle);
            return;
          }

          lastBroadcastedToken = defaultToken;

          if (firstTimeForSession) {
            firstTimeForSession = false;
            createUpdateCdn$(defaultToken)
              .pipe(flog('Update CDN cookie, first time.'))
              .subscribe({
                error: (e) => o.error(e),
                complete: () => o.next(bundle),
              });
            return;
          }

          createUpdateCdn$(defaultToken).subscribe(
            log('Update CDN cookie, non-first time (fire & forget)')
          );

          o.next(bundle);
        },
        error: (e) => o.error(e),
        complete: () => o.complete(),
      });
    });
  };
}
