import {
  estimatedNowAtServerMs,
  estimatedServerDeltaMs,
  getVersionContext,
  setVersionContext,
  observedServerTimeMs,
} from '@heimdall/client';

import * as heimdall from '@heimdall/client';

/* eslint-disable no-console */
const originalFetch = window.fetch;

export function setupSharedTools() {
  console.log(`%cSETUP SHARED TOOLS`, 'background: cyan; padding: 10px;');

  Object.assign(window, {
    heimdall,
    overrideFetchStatus,
    restoreFetch,
    estimatedNowAtServerMs,
    estimatedServerDeltaMs,
    observedServerTimeMs,
    getVersionContext,
    setVersionContext,
  });
}

function restoreFetch() {
  console.log('%cUndo fetch override', 'background: magenta; padding: 5px;');
  Object.assign(window, {
    fetch: originalFetch,
  });
}

function overrideFetchStatus(
  pathPattern = '.',
  overrideStatus = 401,
  times = 2
) {
  let runs = 0;
  const matcher = new RegExp(pathPattern);

  console.log(`%cALLOW FETCH OVERRIDE`, 'background: magenta; padding: 5px;', {
    pathPattern,
    status: overrideStatus,
    times,
    matcher,
    runs,
  });

  Object.assign(window, { fetch: ourFetch });

  function ourFetch(input: RequestInfo | string, init?: RequestInit) {
    const targetUrl = typeof input === 'string' ? input : input.url;
    const isAMatch = matcher.test(targetUrl);

    if (!isAMatch) {
      return originalFetch(input, init);
    }

    if (runs++ >= times) {
      console.log(
        `%cRestore original fetch`,
        'background: khaki; padding: 3px;'
      );
      restoreFetch();
      return originalFetch(input, init);
    }

    console.log(
      `%cOVERRIDE CALL ${runs}/${times} ${targetUrl} ${overrideStatus}`,
      `background: yellow; color: blue; padding: 20px;`,
      { targetUrl }
    );

    return originalFetch(`https://httpbin.org/status/${overrideStatus}`);
  }
}
