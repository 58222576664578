import '@refinitiv-ui/elements/loader';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../components/heimdall-frame.js';
import '../theme.js';
import { isNextPathValid } from '../config.js';

import { optOut } from '@heimdall/client';

@customElement('heimdall-optout')
export class HeimdallOptout extends LitElement {
  @state()
  _error = '';

  firstUpdated() {
    const searchParams = new URLSearchParams(document.location.search);
    const nextPath = searchParams.get('nextPath');
    if (nextPath && isNextPathValid(nextPath)) {
      optOut()
        .then(() => {
          document.location.replace(nextPath);
        })
        .catch((e) => (this._error = e));
    } else {
      this._error = 'nextPath query param is missing or invalid';
      return;
    }
  }

  render() {
    return html`<heimdall-frame> ${this.getFrameContent()} </heimdall-frame>`;
  }

  getFrameContent() {
    if (this._error) {
      return html`
        <h2>Error</h2>
        <p>${this._error}</p>
      `;
    }

    return html`<h2>Redirecting...</h2>
      <ef-loader></ef-loader>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-optout': HeimdallOptout;
  }
}
