import {
  finalize,
  firstValueFrom,
  interval,
  Observable,
  of,
  switchMap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { indicateInterest } from './distributed-interest.js';
import type { TokenBundleIdp, TokenSetIdp } from './internal.js';
import { isIdpBundle } from './internal.js';
import {
  loadStructure,
  removeLocalStorageValue,
  saveStructure,
} from './local-storage.js';
import { flog } from './helpers.js';
import { createStatelessToken$ } from './token-stateless.js';
import { isFreshTokenBundle } from './token-time.js';

export const knownLinkOriginByKey: Record<string, string> = {
  lh: 'http://localhost:8080',
  '3rd': 'https://thirdparty.local:8081',
  cqa: 'https://cdn.qa.refinitiv.com',
  cppe: 'https://cdn.ppe.refinitiv.com',
  cprod: 'https://cdn.refinitiv.com',
  wsqa: 'https://workspace.qa.refinitiv.com',
  wsppe: 'https://workspace.ppe.refinitiv.com',
  wsprod: 'https://workspace.refinitiv.com',
  ws: 'https://workspace.refinitiv.com',
};

export function createRequestForeignIdpToken$(
  linkUrl = '/public/heimdall/link.html',
  {
    silentLogIn = false,
    persistBundle = true,
    idpTokenTimeoutMs = 2 * 60_000,
    checkWindowStillOpenMs = 1_000,
    remoteLogin = false,
  } = {}
): Observable<TokenSetIdp> {
  let proxy: WindowProxy | null;
  let frame: HTMLIFrameElement | null;

  return new Observable<TokenSetIdp>((o) => {
    removeLocalStorageValue('heimdall:token:idp:transient');

    const ourOrigin = document.location.origin;
    const targetUrl = new URL(linkUrl, ourOrigin);
    const originKey = Object.keys(knownLinkOriginByKey).find(
      (k) => knownLinkOriginByKey[k] === ourOrigin
    );

    if (originKey) {
      targetUrl.searchParams.append('klo', originKey);
    }

    targetUrl.searchParams.append('idp', 'entra');

    if (silentLogIn) {
      targetUrl.searchParams.append('silent', 'true');
    }

    const listener = (
      event: MessageEvent<
        | TokenBundleIdp
        | {
            error: string;
            errorDescription: string;
            ref: 'LINK';
          }
      >
    ) => {
      const bundle = event.data;

      if (bundle.ref !== 'LINK') {
        return;
      }

      if ('error' in bundle) {
        o.error(new Error(`${bundle.error}`));
        return;
      }

      if (!isIdpBundle(bundle)) {
        return;
      }

      if (event.origin !== new URL(linkUrl, ourOrigin).origin) {
        o.error(new Error('Invalid origin'));
        return;
      }

      if (remoteLogin) {
        bundle.idpConfig.remoteLogin = true;
        saveStructure('heimdall:token:idp', bundle);
        indicateInterest('heimdall:idp');
      } else if (persistBundle) {
        saveStructure('heimdall:token:idp:transient', bundle);
      }

      o.next(bundle.tokenSet);
      o.complete();
    };

    window.addEventListener('message', listener);

    if (silentLogIn) {
      frame = getOrCreateIframe('heimdall');

      if (!frame) {
        o.error(new Error('Unable to locate window for IDP token request'));
        return;
      }

      frame.src = targetUrl.toString();
    } else {
      proxy = window.open(targetUrl, 'idp-relay', 'width=410,height=568');
      if (!proxy) {
        o.error(new Error('Unable to open window for IDP token request'));
      }
    }

    const windowCheck = interval(checkWindowStillOpenMs)
      .pipe(flog('Check IDP window is open'))
      .subscribe(() => {
        if (proxy?.closed) {
          o.error(new Error('User closed window'));
        }
      });

    return () => {
      window.removeEventListener('message', listener);
      proxy?.close();
      frame && (frame.src = '');
      windowCheck.unsubscribe();
    };
  }).pipe(
    flog('Request foreign IDP token'),
    timeout({
      first: idpTokenTimeoutMs,
      with: () => {
        return throwError(() => new Error('Link timeout'));
      },
    })
  );
}

export const requestIdpToken = async (linkUrl = '/public/heimdall/link.html') =>
  firstValueFrom(createRequestForeignIdpToken$(linkUrl));

export function createCheckIdLink$({
  initialWaitMs = 2000,
  retryMinIntervalMs = 10_000,
  retryMaxIntervalMs = 15_000,
  idLinkTotalTimeoutMs = 2 * 60_000,
  maxRetries = 50,
  cleanupOnComplete = false,
} = {}) {
  return new Observable((o) => {
    return of(loadStructure('heimdall:token:idp:transient'))
      .pipe(
        switchMap((tokenBundleIdp) => {
          if (!tokenBundleIdp || !isFreshTokenBundle(tokenBundleIdp)) {
            return throwError(() => new Error('Idp token invalid or missing.'));
          }

          return timer(initialWaitMs).pipe(
            switchMap(() =>
              createStatelessToken$(tokenBundleIdp, 'LNK-CHK', {
                count: maxRetries,
                ditherMin: retryMinIntervalMs,
                ditherMax: retryMaxIntervalMs,
                backOffBase: 1,
                retryClientErrors: true,
              }).pipe(flog('Id check swap'))
            )
          );
        }),
        finalize(() => {
          if (cleanupOnComplete) {
            removeLocalStorageValue('heimdall:token:idp:transient');
          }
        })
      )
      .subscribe({
        next: () => {
          o.complete();
        },
        error: (e) => {
          o.error(e);
        },
      });
  }).pipe(
    timeout({
      first: idLinkTotalTimeoutMs,
      with: () => {
        return throwError(() => new Error('Check ID link timeout'));
      },
    }),
    flog('Check ID link')
  );
}

function getOrCreateIframe(id: string, hidden = true) {
  let iframe = document.getElementById(id) as HTMLIFrameElement;
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = id;

    if (hidden) {
      iframe.style.display = 'none';
    }

    document.body.appendChild(iframe);
  } else {
    iframe.style.display = hidden ? 'none' : '';
  }

  return iframe;
}
