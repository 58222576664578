import { Observable, OperatorFunction, filter, map, of, switchMap } from 'rxjs';
import { createActiveAudiences$ } from './active-audiences.js';
import { rotateDpopKeys$ } from './dpop.js';
import { estimatedNowAtServerMs } from './estimated-now-at-server.js';
import {
  DpopMode,
  MaybeHeimdallBundle,
  TokenAudience,
  TokenBundleHeimdall,
  TokenBundleIdp,
  TokenByAudience,
  TokenSet,
  TokenSetHeimdallResult,
  TokenSetIdp,
  assertIsHeimdallTokenSet,
  audiences,
} from './internal.js';
import { chainOps, createRetryFetch$, flog } from './helpers.js';
import { packTokenBundleHeimdall } from './token-refresh.js';
import { createTokenSwapParams } from './url-helper.js';

export function createAlignTokenAudience$(
  tokenBundleIdp: TokenBundleIdp,
  forceAudience: TokenAudience | undefined,
  maybeHeimdallBundle: MaybeHeimdallBundle
) {
  return createActiveAudiences$().pipe(
    switchMap((activeAudiences) =>
      of(maybeHeimdallBundle).pipe(
        deleteIfExpired,
        chainOps([
          ...[...audiences]
            .reverse()
            .map(stepDown(activeAudiences, tokenBundleIdp)),

          ...[...audiences].map(
            stepUp(activeAudiences, tokenBundleIdp, forceAudience)
          ),
        ]),
        filter(Boolean)
      )
    )
  );
}

function stepUp(
  activeAudiences: TokenAudience[],
  idpTokenInfo: TokenBundleIdp,
  forceAudience: TokenAudience | undefined
) {
  return (audience: TokenAudience) => {
    return switchMap((bundle: MaybeHeimdallBundle) => {
      const { idpConfig, tokenSet: tokenSetIdp } = idpTokenInfo;
      const {
        clientUid,
        heimdallTokenEndpoint,
        swapTokenType,
        dpopMode,
        defaultAudiences,
      } = idpConfig;

      const policy = stepUpPolicy(
        audience,
        defaultAudiences,
        activeAudiences,
        bundle,
        forceAudience
      );

      if (policy == 'SKIP') {
        return of(bundle);
      }

      if (!heimdallTokenEndpoint) {
        throw new Error(
          `Unable to step-up, ${audience} no heimdall endpoint found`
        );
      }

      return (
        policy == 'FROM_SCRATCH'
          ? rotateDpopKeys$().pipe(
              flog('Rotate DPoP keys for swap'),
              switchMap(() => {
                return createFromScratch$(
                  tokenSetIdp,
                  swapTokenType,
                  clientUid,
                  audience,
                  defaultAudiences,
                  heimdallTokenEndpoint,
                  dpopMode
                );
              })
            )
          : createFromStepUp$(
              tokenSetIdp,
              swapTokenType,
              bundle,
              audience,
              heimdallTokenEndpoint,
              dpopMode
            )
      ).pipe(resultToBundle(heimdallTokenEndpoint));
    });
  };
}

function stepDown(
  activeAudiences: TokenAudience[],
  idpTokenInfo: TokenBundleIdp
) {
  return (audience: TokenAudience) => {
    return switchMap((bundle: MaybeHeimdallBundle) => {
      const isInActiveAudiences = activeAudiences.includes(audience);
      const audiencesInCurrentBundle = audiencesInBundle(bundle);

      const { idpConfig } = idpTokenInfo;
      const { heimdallTokenEndpoint, defaultAudiences, dpopMode } = idpConfig;

      const isDefault = defaultAudiences.includes(audience);
      const isInCurrentBundle = audiencesInCurrentBundle.has(audience);
      const nothingToDo =
        !bundle || isInActiveAudiences || !isInCurrentBundle || isDefault;

      if (nothingToDo) return of(bundle);

      return createStepDown$(
        bundle,
        audience,
        heimdallTokenEndpoint,
        dpopMode
      ).pipe(resultToBundle(heimdallTokenEndpoint));
    });
  };
}

function createStepDown$(
  bundle: TokenBundleHeimdall,
  audience: TokenAudience,
  heimdallTokenEndpoint: string,
  dpopMode: DpopMode
) {
  const { refreshInfo } = bundle;
  const { refreshToken } = refreshInfo;

  const stepDownParams = new URLSearchParams({
    grant_type: 'step_down',
    refresh_token: refreshToken,
    aud: audience,
  });

  return createRetryFetch$<TokenSetHeimdallResult>(heimdallTokenEndpoint, {
    method: 'POST',
    body: stepDownParams,
    mode: 'cors',
    addHeimdallTrace: true,
    dpopToken: dpopMode ? refreshToken : undefined,
  }).pipe(flog(`Exclude ${audience} through step-down.`));
}

function audiencesInBundle(bundle: MaybeHeimdallBundle): Set<TokenAudience> {
  if (!bundle) return new Set();
  const { tokenSet } = bundle;
  return new Set(audiences.filter((a) => !!tokenSet.access_token[a]));
}

function deleteIfExpired(
  source$: Observable<MaybeHeimdallBundle>
): Observable<MaybeHeimdallBundle> {
  return source$.pipe(
    map((bundle) => {
      if (!bundle) return bundle;

      const { refreshInfo } = bundle;
      const { expiresBy } = refreshInfo;

      if (expiresBy > estimatedNowAtServerMs()) return bundle;

      // eslint-disable-next-line no-console
      console.warn(
        `Ignoring expired Heimdall token bundle - will trade for new.`
      );
      return undefined;
    })
  );
}

function createFromScratch$(
  tokenSetIdp: TokenSetIdp,
  swapTokenType: keyof TokenSetIdp,
  clientUid: string,
  audience: TokenAudience,
  defaultAudiences: TokenAudience[],
  heimdallTokenEndpoint: string,
  dpopMode: DpopMode
) {
  const subjectToken = tokenSetIdp[swapTokenType] as string;
  const swapParams = createTokenSwapParams(
    subjectToken,
    clientUid,
    swapTokenType,
    [...defaultAudiences, audience]
  );

  return createRetryFetch$<TokenSetHeimdallResult>(heimdallTokenEndpoint, {
    method: 'POST',
    body: swapParams,
    mode: 'cors',
    addHeimdallTrace: true,
    dpopToken: dpopMode ? subjectToken : undefined,
  }).pipe(flog(`Include ${audience} by token swap.`));
}

function createFromStepUp$(
  tokenSetIdp: TokenSetIdp,
  swapTokenType: keyof TokenSetIdp,
  bundle: TokenBundleHeimdall | undefined,
  audience: TokenAudience,
  heimdallTokenEndpoint: string,
  dpopMode: DpopMode
) {
  if (!bundle) {
    throw new Error('Unable to step-up without base bundle');
  }

  const { refreshInfo } = bundle;
  const { refreshToken } = refreshInfo;
  const subjectToken = tokenSetIdp[swapTokenType] as string;

  const stepUpParms = new URLSearchParams({
    grant_type: 'step_up',
    aud: audience,
    code_verifier: subjectToken,
    refresh_token: refreshToken,
  });

  return createRetryFetch$<TokenSetHeimdallResult>(heimdallTokenEndpoint, {
    method: 'POST',
    body: stepUpParms,
    mode: 'cors',
    addHeimdallTrace: true,
    dpopToken: dpopMode ? `${refreshToken}|${subjectToken}` : undefined,
  }).pipe(flog(`Include ${audience} through step-up.`));
}

function resultToBundle(
  heimdallTokenEndpoint: string
): OperatorFunction<TokenSet | TokenSetHeimdallResult, TokenBundleHeimdall> {
  return map((set) => {
    assertIsHeimdallTokenSet(set);
    return packTokenBundleHeimdall(heimdallTokenEndpoint, set);
  });
}

export function stepUpPolicy(
  audience: TokenAudience,
  defaultAudiences: TokenAudience[],
  activeAudiences: TokenAudience[],
  bundle: undefined | { tokenSet: { access_token: TokenByAudience } },
  forceAudience: undefined | TokenAudience = undefined
): 'SKIP' | 'FROM_SCRATCH' | 'STEP_UP' {
  const audiencesInBundle = audiences.filter((a) => {
    return (bundle?.tokenSet?.access_token || {})[a];
  });

  const isAlreadyInBundle = audiencesInBundle.includes(audience);
  const isForcedAudience = audience == forceAudience;
  const isActive = activeAudiences.includes(audience);
  const isDefault = defaultAudiences.includes(audience);

  if (isForcedAudience) return 'STEP_UP';
  if (isAlreadyInBundle) return 'SKIP';

  if (isActive) {
    if (!bundle) return 'FROM_SCRATCH';
    if (isDefault) return 'FROM_SCRATCH';
    return 'STEP_UP';
  }

  if (isDefault) return 'FROM_SCRATCH';

  return 'SKIP';
}
