import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '@refinitiv-ui/elements/button';
import '../components/login-frame.js';
import '../components/heimdall-loader.js';
import '../components/heimdall-error.js';
import type { InteractionState } from '../internal.js';
import { createTeamsLoginStart$ } from '../login-teams.js';
import '../theme.js';

@customElement('heimdall-teams')
export class HeimdallTeams extends LitElement {
  @state()
  _interactionState: InteractionState = {
    state: 'STAND_BY',
    reason: 'Initial state',
  };

  firstUpdated() {
    createTeamsLoginStart$().subscribe((interactionState) => {
      this._interactionState = interactionState;
    });
  }

  render() {
    return html`<login-frame> ${this.getFrameContent()} </login-frame>`;
  }

  getFrameContent() {
    if (this._interactionState.state == 'ERROR') {
      return html`<heimdall-error
        .error=${this._interactionState.error}
      ></heimdall-error>`;
    } else {
      return html`<heimdall-loader />`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-teams': HeimdallTeams;
  }
}
