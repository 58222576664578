import { LitElement, html, css, CSSResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import logoUrl from './../assets/lseg_logo_rgb_pos.png';

@customElement('heimdall-frame')
export class HeimdallFrame extends LitElement {
  render() {
    return html`<ef-layout>
      <ef-layout class="frame">
        <img src=${logoUrl} class="logo" alt="Refinitiv logo" />
        <ef-layout class="content">
          <slot></slot>
        </ef-layout>
        <ef-layout class="footer"> </ef-layout>
      </ef-layout>
    </ef-layout>`;
  }

  static get styles(): CSSResult {
    return css`
      .frame {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 550px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: none;
        background-color: #fff;
      }

      .logo {
        display: block;
        margin: 40px auto;
        width: 212px;
      }

      .content {
        flex-grow: 1;
        display: block;
        padding: 50px 30px 10px;
        text-align: center;
      }

      .footer {
        width: 100%;
        height: 40px;
        background-color: #001aff;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-frame': HeimdallFrame;
  }
}
