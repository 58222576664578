import {
  createRetryFetch$,
  defaultAudiencesByClientType,
  knownLinkOriginByKey,
  packWebTokenBundleIdp,
  TokenSetIdp,
} from '@heimdall/client';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { InteractionState } from './internal.js';
import {
  packTokenExchangeParams,
  redirectionFields$,
} from './login-redirect.js';

export function createLinkAccountRedirect$(): Observable<InteractionState> {
  const owner = window.opener || window.parent;

  return redirectionFields$.pipe(
    switchMap((fields) => {
      if (!fields) {
        return of<InteractionState>({
          state: 'ERROR' as const,
          error: new Error('Invalid link redirection fields'),
        });
      }

      const linkOriginKey = fields.linkOriginKey;
      const origin =
        (linkOriginKey && knownLinkOriginByKey[linkOriginKey]) ||
        document.location.origin;

      if ('error' in fields) {
        const { error, errorDescription } = fields;

        owner.postMessage({ ref: 'LINK', error, errorDescription }, origin);

        return of<InteractionState>({
          state: 'ERROR' as const,
          error: new Error(`${fields.error} ${fields.errorDescription}`),
        });
      }

      const {
        clientId,
        tokenEndpoint,
        codeVerifier,
        redirectUri,
        code,
        idpKey,
        swapTokenType = 'access_token',
        heimdallTokenEndpoint,
        dpopMode,
        clientUid,
        clientType,
      } = fields;

      const idpSwapParams = packTokenExchangeParams({
        grantType: 'authorization_code',
        redirectUri,
        clientId,
        code,
        codeVerifier,
      });

      return createRetryFetch$<TokenSetIdp>(tokenEndpoint, {
        method: 'POST',
        body: idpSwapParams,
        mode: 'cors',
      }).pipe(
        tap((set) => {
          const bundle = packWebTokenBundleIdp(tokenEndpoint, set, {
            idpKey,
            clientUid,
            dpopMode,
            tokenEndpoint,
            heimdallTokenEndpoint,
            swapTokenType,
            defaultAudiences: defaultAudiencesByClientType(clientType),
          });

          bundle.ref = 'LINK';

          console.log(
            `%cORIGIN ${linkOriginKey} ${origin}`,
            'background: pink; padding: 10px;'
          );
          owner.postMessage(bundle, origin);
        }),
        map(
          (): InteractionState => ({
            state: 'LOGGED_IN',
          })
        )
      );
    }),
    catchError((error) =>
      of<InteractionState>({
        state: 'ERROR',
        error,
      })
    )
  );
}
