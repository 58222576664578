import {
  alignLocalStorageValue,
  flog,
  setVersionContext,
} from '@heimdall/client';
import { Observable, switchMap, tap } from 'rxjs';
import { appConfig$ } from './config.js';
import { guessContext } from './context-heuristic.js';
import { InteractionState } from './internal.js';
import { clearTransientLocalFields } from './local-fields.js';
import { createFullLoginJourney$ } from './login-web.js';

export function createLinkAccount$(): Observable<InteractionState> {
  return appConfig$.pipe(
    flog('Link account', 'background: pink; padding: 5px;'),
    tap(() => setVersionContext(guessContext())),
    switchMap((appConfig) => {
      const {
        issuerUrl,
        nextPath,
        clientUid: clientUidProvided,
        env,
        loginHint,
        linkRedirectUri,
        linkOriginKey,
        silent,
      } = appConfig;

      const clientUid = clientUidProvided || 'workspace';

      alignLocalStorageValue('heimdall:env', env);
      clearTransientLocalFields();

      return createFullLoginJourney$({
        issuerUrl,
        clientUid,
        redirectUri: linkRedirectUri,
        nextPath,
        loginHint,
        linkOriginKey,
        silent,
      }).pipe(flog('Login journey'));
    })
  );
}
