import '@refinitiv-ui/elements/button';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../components/heimdall-error.js';
import '../components/heimdall-loader.js';
import '../components/login-frame.js';
import type { InteractionState } from '../internal.js';
import { createLoginRedirect$ } from '../login-redirect.js';
import '../theme.js';

@customElement('heimdall-redirect')
export class HeimdallRedirect extends LitElement {
  @state()
  _interactionState: InteractionState = {
    state: 'STAND_BY',
    reason: 'Initial state',
  };

  firstUpdated() {
    createLoginRedirect$().subscribe((interactionState) => {
      this._interactionState = interactionState;
    });
  }

  render() {
    return html`<login-frame> ${this.getFrameContent()} </login-frame>`;
  }

  getFrameContent() {
    if (this._interactionState.state == 'ERROR') {
      return html`<heimdall-error
        .error=${this._interactionState.error}
      ></heimdall-error>`;
    } else {
      return html`<heimdall-loader />`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-redirect': HeimdallRedirect;
  }
}
