import {
  MonoTypeOperatorFunction,
  NEVER,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  shareReplay,
  startWith,
  switchScan,
  tap,
} from 'rxjs';
import { createAlignTokenAudience$ } from './align-token-audience.js';
import {
  createDistributedObservable$,
  createDistributedSubject$,
} from './distributed-observable.js';
import { idpTokenBundle$ } from './idp.js';
import {
  MaybeHeimdallBundle,
  TokenAudience,
  TokenBundleHeimdall,
  TokenByAudience,
  assertIsHeimdallBundle,
  audiences,
  stepUpResourceId,
} from './internal.js';
import { perSessionEsoListenerOperator } from './platform-notifications.js';
import { isFreshTokenBundle } from './token-time.js';

import { createInterest$ } from './distributed-interest.js';
import { estimatedNowAtServerMs } from './estimated-now-at-server.js';
import { logoutOnError } from './logout.js';
import { flog, quickFieldOrderedCompare } from './helpers.js';
import { keepTokenFresh } from './token-refresh.js';
import { perSessionCdnUpdateOperator } from './cdn.js';

export const tokenSet$ = createTokenSet$();

export const forceStepUp$ = createDistributedSubject$<
  TokenAudience | undefined
>('heimdall:force-step-up');

const tokenByAudienceCompare =
  quickFieldOrderedCompare<TokenByAudience>(audiences);

function createTokenSet$() {
  return createDistributedObservable$<TokenBundleHeimdall>(
    'heimdal:token-set',
    (o, i$, currentValue) => {
      return combineLatest({
        interest: merge(
          i$ ? i$.pipe(flog(`Token-set D-O interest`)) : NEVER,
          createInterest$(stepUpResourceId).pipe(
            startWith('something'),
            flog(`Token-set external interest`)
          )
        ),
        tokenBundleIdp: idpTokenBundle$,
        forcedAudience: forceStepUp$.pipe(startWith(undefined)),
      })
        .pipe(
          unifySignals(),
          switchScan((bundle: MaybeHeimdallBundle, next) => {
            const { tokenBundleIdp, forcedAudience } = next;

            if (!tokenBundleIdp || !isFreshTokenBundle(tokenBundleIdp)) {
              return NEVER.pipe(flog('Idp token invalid or missing - hold.'));
            }

            const { remoteLogin } = tokenBundleIdp.idpConfig;

            return createAlignTokenAudience$(
              tokenBundleIdp,
              forcedAudience,
              bundle
            ).pipe(
              keepTokenFresh(
                'heimdall',
                true,
                tokenBundleIdp.idpConfig.dpopMode
              ),
              filter(Boolean),
              map((b) => {
                assertIsHeimdallBundle(b);
                return b;
              }),
              resetForcedAudience(forcedAudience),
              map(emptyOutAudiencesForExpiredTokens),
              logoutOnError(emptyOutForLogoutFallback(bundle)),
              remoteLogin
                ? (s) => s.pipe(flog('Remote login: skip CDN updates'))
                : perSessionCdnUpdateOperator
            );
          }, currentValue),

          perSessionEsoListenerOperator,
          logoutOnError()
        )
        .subscribe(o);
    }
  ).pipe(
    map((bundle) => bundle.tokenSet.access_token),
    distinctUntilChanged((a, b) => tokenByAudienceCompare(a, b)),
    flog(`Token set`),
    shareReplay({
      bufferSize: 1,
      refCount: false, // keep alive
    })
  );
}

function resetForcedAudience<T>(
  audience: TokenAudience | undefined
): MonoTypeOperatorFunction<T> {
  return tap(() => {
    if (audience) {
      forceStepUp$.next(undefined);
    }
  });
}

function emptyOutAudiencesForExpiredTokens(
  bundle: TokenBundleHeimdall
): TokenBundleHeimdall {
  const { refreshInfo, tokenSet } = bundle;
  const { expiresBy } = refreshInfo;
  if (expiresBy > estimatedNowAtServerMs()) return bundle;
  return { ...bundle, tokenSet: { ...tokenSet, access_token: {} } };
}

function emptyOutForLogoutFallback(bundle: MaybeHeimdallBundle) {
  return (
    bundle && {
      ...bundle,
      tokenSet: {
        ...bundle.tokenSet,
        access_token: {},
      },
    }
  );
}

function unifySignals<T>(): MonoTypeOperatorFunction<T> {
  return debounceTime(5);
}
